import React from "react";
import styled, { css } from "styled-components";

const Space = styled.div`
  ${({ units }) => css`
    height: ${units}px;
  `}
`;

const VerticalSpace = ({ units }) => <Space units={units} />;

export default VerticalSpace;
