import React from "react";
import styled from "styled-components";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import VerticalSpace from "../../VerticalSpace";
import { Icon } from "../../icons";

const Container = styled.div`
  width: 100%;
  padding: 20px 10px;

  & > h3 {
    color: var(--slate);
    font-weight: 700;
  }

  .grid-posts {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 30px;

    @media (max-width: 330px) {
      grid-template-columns: 1fr;
    }
  }

  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 200px;
  }
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  h3 {
    margin: 15px 0 1em;
  }

  p {
    line-height: 1.3;
  }

  /* TODO: Centralize this style */

  .lenTime {
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: center;
    color: var(--slate);
    font-size: var(--fz-sm);
    font-weight: 500;

    & svg {
      width: 4px;
      height: 4px;
    }
  }
`;

const CardPost = ({ post }) => (
  <StyledCard>
    <GatsbyImage image={post?.image} alt={post.title} />
    <h3>
      <Link to={`/blog/${post.slug}`}>{post.title}</Link>
    </h3>
    <p className="sub-text">{post.description}</p>
    <div className="lenTime">
      <span>{post?.timeToRead} min read</span>
      <Icon name="Ellipse" />
      <span>{post?.publishedDate}</span>
    </div>
  </StyledCard>
);

const MorePosts = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
          edges {
            node {
              title
              id
              slug
              featuredImage {
                gatsbyImageData
              }
              publishedDate(formatString: "MMM D")
              description {
                childMarkdownRemark {
                  excerpt(pruneLength: 150)
                }
              }
              fields {
                timeToRead
              }
            }
          }
          totalCount
        }
      }
    `
  );

  if (data.allContentfulBlogPost.totalCount === 0) return null;

  return (
    <>
      <hr className="divider" />
      <Container>
        <h3>More Posts</h3>

        <VerticalSpace units={20} />

        <div className="grid-posts">
          {data.allContentfulBlogPost.edges.map((edge) => {
            return (
              <CardPost
                key={edge.node.id}
                post={{
                  title: edge.node.title,
                  description:
                    edge.node.description?.childMarkdownRemark.excerpt,
                  publishedDate: edge.node.publishedDate,
                  image: edge.node.featuredImage?.gatsbyImageData,
                  slug: edge.node.slug,
                  timeToRead: edge.node.fields.timeToRead,
                }}
              />
            );
          })}
        </div>
      </Container>
      <hr className="divider" />
    </>
  );
};

export default MorePosts;
