import styled from "styled-components";

export const StyledMainContainer = styled.main`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  margin-top: 150px;
  gap: 20px;
  max-width: 1080px;

  @media (max-width: 1080px) {
    margin-top: 130px;
  }
  @media (max-width: 768px) {
    margin-top: 115px;
  }
`;
