import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const TitleText = styled.h1`
  line-height: normal;
  font-weight: 700;
  color: var(--lightest-slate);
  align-self: flex-start;
`;

const Title = ({ children }) => (
  <TitleText className="medium-heading">{children}</TitleText>
);

Title.propTypes = {
  chidlren: PropTypes.node.isRequired,
};

export default Title;
