import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import prettier from "prettier/standalone";
import parserBabel from "prettier/parser-babel";

const CodeBlock = ({ code: codeString }) => {
  let formattedCode;
  try {
    formattedCode = prettier.format(codeString, {
      parser: "babel",
      plugins: [parserBabel],
    });
  } catch (err) {
    formattedCode = codeString;
  }

  return (
    <SyntaxHighlighter
      language="javascript"
      style={atomOneDark}
      customStyle={{ borderRadius: "8px" }}
      showLineNumbers
      wrapLongLines
    >
      {formattedCode}
    </SyntaxHighlighter>
  );
};

export default CodeBlock;
