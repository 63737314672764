import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { Layout } from "@components";
import Body from "./body";

import { StyledMainContainer } from "./styles";
import Title from "./components/Title";
import Author from "../Author";
import VerticalSpace from "../VerticalSpace";
import MorePosts from "./MorePosts";
import TagList from "../TagList";

export const query = graphql`
  query ($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      description {
        childMarkdownRemark {
          excerpt(pruneLength: 250)
        }
      }
      publishedDate(formatString: "MMM D")
      featuredImage {
        gatsbyImageData(width: 1000, height: 750, quality: 100)
      }
      featuredImageCaption
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(width: 700, height: 300, quality: 90)
          }
        }
      }
      metadata {
        tags {
          name
        }
      }
      fields {
        timeToRead
      }
    }
    allContentfulLandingPage {
      edges {
        node {
          blogPhoto {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

const BlogPost = ({ data, location }) => {
  const image =
    data.allContentfulLandingPage.edges[0]?.node.blogPhoto?.gatsbyImageData;

  return (
    <Layout location={location}>
      <Helmet title={data.contentfulBlogPost.title}>
        {data.contentfulBlogPost.description && (
          <meta
            name="description"
            content={
              data.contentfulBlogPost.description.childMarkdownRemark.excerpt
            }
          />
        )}
      </Helmet>

      <StyledMainContainer className="fillHeight">
        <Title>{data.contentfulBlogPost.title}</Title>

        <VerticalSpace units={10} />

        {data.contentfulBlogPost.description && (
          <p className="sub-text">
            {data.contentfulBlogPost.description.childMarkdownRemark.excerpt}
          </p>
        )}

        <VerticalSpace units={10} />

        {image && (
          <Author
            imgSrc={image}
            publishedDate={data.contentfulBlogPost.publishedDate}
            timeToRead={data.contentfulBlogPost.fields.timeToRead}
          />
        )}

        <VerticalSpace units={10} />

        <VerticalSpace units={50} />

        {data.contentfulBlogPost.featuredImage && (
          <>
            <GatsbyImage
              image={getImage(
                data.contentfulBlogPost.featuredImage.gatsbyImageData
              )}
              alt={data.contentfulBlogPost.title}
              className="img"
            />
            <div
              className="caption"
              dangerouslySetInnerHTML={{
                __html: data.contentfulBlogPost.featuredImageCaption,
              }}
            />
          </>
        )}

        <VerticalSpace units={10} />

        <Body data={data} />

        <VerticalSpace units={100} />

        <TagList tags={data.contentfulBlogPost.metadata.tags} />

        <MorePosts />
      </StyledMainContainer>
    </Layout>
  );
};

BlogPost.propTypes = {
  location: PropTypes.object.isRequired,
};

export default BlogPost;
