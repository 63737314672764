import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Icon } from "../icons";

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  gap: 15px;
  width: 100%;

  @media (max-width: 400px) {
    gap: 20px;
  }

  .gatsby-image-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  & > :last-child {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  /* TODO: Centralize this style */

  .lenTime {
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: center;
    color: var(--slate);
    font-size: var(--fz-sm);
    font-weight: 500;

    & svg {
      width: 4px;
      height: 4px;
    }
  }

  .author-n {
    color: var(--lightest-slate);
    font-weight: 700;
  }
`;

const Author = ({ imgSrc, publishedDate, timeToRead }) => (
  <Container>
    {imgSrc && (
      <GatsbyImage
        image={getImage(imgSrc)}
        alt="Dedaldino Antonio"
        className="img"
      />
    )}
    <div>
      <p className="author-n">Dedaldino Antonio</p>
      <div className="lenTime">
        <span>{timeToRead} min read</span>
        <Icon name="Ellipse" />
        <span>{publishedDate}</span>
      </div>
    </div>
  </Container>
);

Author.propTypes = {
  imgSrc: PropTypes.string,
  publishedDate: PropTypes.string,
  timeToRead: PropTypes.number,
};

export default Author;
