import React from "react";
import styled from "styled-components";

const ListContainer = styled.ul`
  list-style: none;
  width: 100%;
  padding-left: 0;
  gap: 10px;
  display: flex;
  flex-flow: row wrap;

  li {
    cursor: pointer;
    color: var(--light-slate);

    :hover,
    :focus {
      transition: var(--transition);
      color: var(--green);
    }
  }
`;

const TagList = ({ tags = [] }) => {
  if (tags.length === 0) return null;
  return (
    <ListContainer>
      {tags.map((tag, i) => (
        <li key={i}>{tag.name}</li>
      ))}
    </ListContainer>
  );
};

export default TagList;
