import React from "react";
import styled from "styled-components";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import CodeBlock from "../CodeBlock";
import VerticalSpace from "../VerticalSpace";

const Bold = ({ children }) => (
  <span style={{ fontWeight: "600" }}>{children}</span>
);

const Text = styled.p`
  font-weight: 300;
  color: var(--slate);
  line-height: 1.4;
`;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.CODE]: (code) => <CodeBlock code={code} />,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      if (node.data?.target?.gatsbyImageData) {
        // Asset is an image
        return (
          <>
            <VerticalSpace units={10} />
            <GatsbyImage
              image={getImage(node.data?.target?.gatsbyImageData)}
              alt="image"
            />
            <VerticalSpace units={30} />
          </>
        );
      }
      return (
        // TODO: Render this Asset apropriately
        <>
          <h2>Embedded Asset</h2>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
  },
};

export default function Body({ data }) {
  const { body } = data.contentfulBlogPost;

  return (
    <div className="light-sub-text">
      {body && renderRichText(body, options)}
    </div>
  );
}
